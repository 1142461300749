;(function ($) {
  let navFlag = false

  $(".page-nav-box").css({
    height: $(".page-nav").outerHeight() + "px",
  })

  $(window).on("load resize orientationchange", function () {
    if (max(640)) {
      $("#common-nav").css({
        top: $("#common-header").outerHeight() + "px",
        height: window.innerHeight - $("#common-header").outerHeight() + "px",
      })
    } else {
      navFlag = false

      $("#nav-torigger").removeClass("active")

      $("#common-nav").css({
        display: "",
        opacity: "",
        top: "",
        height: "",
      })

      $(".hoverNav").children(".child").css("display", "")
      $(".hoverNav").children("a").removeClass("open")
    }

    $("#wrapper").css({
      paddingTop: $("#common-header").outerHeight() + "px",
    })

    $(".page-nav-box").css({
      height: $(".page-nav").outerHeight() + "px",
    })
  })

  $("#nav-torigger").click(function () {
    if (!navFlag) {
      $(this).addClass("active")
      TweenMax.to("#common-nav", 0.5, {
        display: "block",
        alpha: 1,
        onComplete: function () {
          navFlag = true
        },
      })
    } else {
      $(this).removeClass("active")
      TweenMax.to("#common-nav", 0.5, {
        alpha: 0,
        onComplete: function () {
          TweenMax.set("#common-nav", { display: "none" })
          navFlag = false
        },
      })
    }
  })

  let l, x, y, s, d
  $(".anm").each(function () {
    if ($(this).attr("data-x")) {
      x = $(this).attr("data-x")
    } else {
      x = 0
    }
    if ($(this).attr("data-y")) {
      y = $(this).attr("data-y")
    } else {
      y = 0
    }
    TweenMax.set($(this), { x: x, y: y, alpha: 0 })
    $(this).attr("data-flag", "0")
  })

  $(window).on("load scroll", function () {
    let scroll = $(window).scrollTop()
    let windowHeight = $(window).height()
    let targetElemen
    $(".anm").each(function () {
      if ($(this).attr("data-flag") == "0") {
        s = $(this).attr("data-s")
        if ($(window).width() > 640) {
          d = $(this).attr("data-d")
        } else {
          if ($(this).attr("data-dsp") != undefined) {
            d = $(this).attr("data-dsp")
          } else {
            d = 0
          }
        }
        if ($(this).attr("data-child") == "1" && $(window).width() > 640) {
          targetElement = $(this).parents(".anm-parent").offset().top
        } else {
          targetElement = $(this).offset().top
        }
        if (scroll > targetElement - (windowHeight / 4) * 3) {
          TweenMax.to($(this), s, { delay: d, x: 0, y: 0, alpha: 1 })
          $(this).attr("data-flag", "1")
        }
      }
    })
    $(".title-type3").each(function () {
      let delay
      let target = $(this)
      if (!target.hasClass("active")) {
        targetElement = target.offset().top
        if ($(window).width() > 640) {
          delay = target.attr("data-d")
        } else {
          delay = 0
        }
        if (scroll > targetElement - (windowHeight / 4) * 3) {
          setTimeout(function () {
            target.addClass("active")
          }, delay)
        }
      }
    })

    $(".pro-box").each(function (i) {
      if (scroll + $("#common-header").outerHeight() >= $(this).offset().top) {
        $(".page-nav-num li")
          .eq(i)
          .addClass("active")
          .siblings("li")
          .removeClass("active")
      }
    })
  })

  $(".slide-images").each(function () {
    let src = $(this).find("img").attr("src")
    let title = $(this).find("span").html()
    $(this).attr({
      href: src,
      "data-title": title,
    })
  })

  $(".slide-src").each(function () {
    let src = $(this).attr("data-src")
    let title = $(this).find("span").html()
    $(this).attr({
      href: src,
      "data-title": title,
    })
  })

  $(".hoverNav").hover(
    function () {
      if (!max(768)) {
        $(this).children(".child").stop().fadeIn()
      }
    },
    function () {
      if (!max(768)) {
        $(this).children(".child").stop().delay(100).fadeOut()
      }
    }
  )

  $(".sp-torigger").click(function () {
    if (max(640)) {
      $(this).toggleClass("open")
      $(this).next(".child").stop().slideToggle()
      return false
    }
  })

  $(".smooth").click(function () {
    var href = $(this).attr("data-href")
    var target = $(href == "#" || href == "" ? "html" : href)
    var position = target.offset().top - $("#common-header").outerHeight() + 1
    if (max(640)) {
      $("#nav-torigger").removeClass("active")
      TweenMax.to("#common-nav", 0.5, {
        alpha: 0,
        onComplete: function () {
          $("html, body").animate({ scrollTop: position }, 800, "swing")
          TweenMax.set("#common-nav", { display: "none" })
          navFlag = false
        },
      })
    } else {
      $("html, body").animate({ scrollTop: position }, 800, "swing")
    }
    return false
  })

  $(window).on("load", function () {
    var url = $(location).attr("href")
    if (url.indexOf("?id=") != -1) {
      var id = url.split("?id=")
      var $target = $("#" + id[id.length - 1])
      if ($target.length) {
        var pos = $target.offset().top - $("#common-header").outerHeight()
        $("html, body").animate({ scrollTop: pos }, 1500)
        if($('.torigger[data-case="'+id[id.length - 1]+'"]').length) {
          $('.torigger[data-case="'+id[id.length - 1]+'"]').addClass('active').text('回答を閉じる').prev('.target').show();
        }
      }

    }
  })

  function max($w) {
    if ($(window).width() <= $w) {
      return true
    } else {
      return false
    }
  }
})(jQuery)
